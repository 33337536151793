import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import _ from "lodash"
import * as style from "./index.module.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Articles from "../components/articles_mini"

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Home" />
    <div className={style.mainIndex}>
        <div className={style.headerContainer}>
          <GatsbyImage
            image={data.headerImage.childImageSharp.gatsbyImageData} 
            alt="Header"/>
          <div className={style.indexMessage}>
            Hi, I'm Jason
          </div>
        </div>
        <div>
          <Articles allMarkdownRemark={data.articlesMiniData} />
        </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
{
  headerImage: file(relativePath: {eq: "header.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width:1250)
    }
  }
  articlesMiniData: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          id
          frontmatter {
            title
            date
            tags
            author
          }
          fields {
            slug
          }
        }
      }
    }
}
`