import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import _ from "lodash"

import * as style from "./articles_mini.module.css"

// Component to place a conditional wrapper around content.
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const ArticlesMiniIndex = ({ allMarkdownRemark }) => {
    console.log(allMarkdownRemark);
    const posts = allMarkdownRemark.edges;
  
    return (
        <section className={style.articlelist}>
          <ul>
            {posts.map(({ node }, index) => (
              <li key={index} className={style.listitem}>
                <div>
                {node.frontmatter.featimg && (
                  <figure className={style.featimg}>
                    <Link to={node.fields.slug}>
                      <Img
                        fixed={node.frontmatter.featimg.childImageSharp.fixed}
                        alt={node.frontmatter.title}
                      />
                    </Link>
                  </figure>
                )}
                <ConditionalWrapper
                  // If featured image, wrap content in <div>.
                  condition={node.frontmatter.featimg}
                  wrapper={children => (
                    <div className={style.article__wrap}>{children}</div>
                  )}
                >
                  <div>
                    <Link to={node.fields.slug}>
                      <h1 className={style.article__title}>
                        {node.frontmatter.title}
                      </h1>
                    </Link>
                  </div>
  
                  
                  <div
                    className={style.article__content}
                    dangerouslySetInnerHTML={{ __html: node.excerpt }}
                  />
                  <div className={style.article__meta}>
                    <table className={style.tableauthor}>
                      <td>
                        {node.frontmatter.author}
                      </td>
                      <td>
                        {new Date(node.frontmatter.date).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                      </td>
                      <td>
                      Filed under:{" "}
                    {node.frontmatter.tags.map((tags, index) => [
                      index > 0 && ", ",
                      <Link key={index} to={`/tags/${_.kebabCase(tags)}`}>
                        {tags}
                      </Link>,
                    ])}
                      </td>
                    </table>
                  </div>
                  <div className={style.article__tax}>
                    
                  </div>
                </ConditionalWrapper>
                </div>
              </li>
            ))}
          </ul>
        </section>
    )
  }

export default ArticlesMiniIndex